import { flatMap, groupBy } from 'lodash'

import * as ActionTypes from '../../actions/actionTypes'
import { TasksState } from '../../actions/tasksTypes'
import { TaskType } from '../../types'
import { STATUS } from '../../constants'
import { getUsername } from '../../utils'

const initialState: TasksState = {
  byStatus: {},
  totalByStatus: {},
  allWellIdsByTaskStatus: {},
  isTasksLookUpPending: false,
  isUpdateTaskPending: false,
}

const isEscalated = (task: TaskType, user: string) => {
  const hasEscalation = task.escalated && task.escalated.startsWith('true')
  const performedEscalation = task.escalatedBy && task.escalatedBy.includes(user.toLowerCase())

  return hasEscalation && performedEscalation
}

const tasksByWellReducer = (state: TasksState = initialState, action): TasksState => {
  switch (action.type) {
    case ActionTypes.GET_TASK: {
      return {
        ...state,
        isTasksLookUpPending: true,
      }
    }
    case ActionTypes.GET_OPENSEARCH_TASKS_BY_WELL: {
      const newStatuses = {}
      if (typeof action.payload.status === 'string') {
        newStatuses[action.payload.status] = true
      } else {
        action.payload.status.forEach(s => {
          newStatuses[s] = true
        })
      }
      return {
        ...state,
        isTasksLookUpPendingByStatus: {
          ...state.isTasksLookUpPendingByStatus,
          ...newStatuses,
        },
      }
    }

    case ActionTypes.GET_OPENSEARCH_TASKS_BY_WELL_SUCCESS: {
      const totalTasksByStatusObj = { ...action.payload.totalTasksByStatus }
      Object.keys(totalTasksByStatusObj).forEach(key => {
        totalTasksByStatusObj[key] = false
      })

      return {
        ...state,
        byStatus: { ...state.byStatus, ...action.payload.tasksByStatus },
        totalByStatus: { ...state.totalByStatus, ...action.payload.totalTasksByStatus },
        isTasksLookUpPending: false,
        isTasksLookUpPendingByStatus: {
          ...state.isTasksLookUpPendingByStatus,
          ...totalTasksByStatusObj,
        },
      }
    }

    case ActionTypes.GET_TASK_SUCCESS: {
      const { payload: updatedTask, user } = action

      const { Escalated, ...uniqueTasks } = state.byStatus
      const allTasks = flatMap(uniqueTasks)

      const index = allTasks.findIndex(({ taskId }) => taskId === updatedTask.taskId)
      if (index !== -1) {
        allTasks[index] = updatedTask
      } else {
        allTasks.push(updatedTask)
      }

      const tasks = allTasks.map(task => ({
        ...task,
        isEscalated: isEscalated(task, getUsername(user)),
      }))

      const updatedTasksByStatus = groupBy(tasks, 'status')
      updatedTask.isEscalated = isEscalated(updatedTask, getUsername(user))
      const escalated =
        updatedTask.isEscalated && !Escalated.find(({ taskId }) => taskId === updatedTask.taskId)
          ? [...Escalated, ...[updatedTask]]
          : Escalated

      updatedTasksByStatus[STATUS.ESCALATED] = escalated

      return {
        ...state,
        byStatus: updatedTasksByStatus,
        isTasksLookUpPending: false,
      }
    }

    case ActionTypes.CREATE_TASK_SUCCESS: {
      const { payload: task, user } = action

      const { Escalated, ...uniqueTasks } = state.byStatus
      // const totalByStatusObj = {...state.totalByStatus}

      const allTasks = flatMap(uniqueTasks)
      allTasks.push(task)
      // totalByStatusObj[task.status] = totalByStatusObj[task.status] + 1

      const tasks = allTasks.map(task => ({
        ...task,
        isEscalated: isEscalated(task, getUsername(user)),
      }))

      const updatedTasksByStatus = groupBy(tasks, 'status')

      task.isEscalated = isEscalated(task, getUsername(user))
      const escalated =
        task.isEscalated && !Escalated.find(({ taskId }) => taskId === task.taskId)
          ? [...Escalated, ...[task]]
          : Escalated
      // totalByStatusObj[STATUS.ESCALATED] = totalByStatusObj[STATUS.ESCALATED] + (escalated.length - Escalated.length)

      updatedTasksByStatus[STATUS.ESCALATED] = escalated

      return {
        ...state,
        byStatus: updatedTasksByStatus,
        // totalByStatus: totalByStatusObj,
        isUpdateTaskPending: false,
      }
    }

    case ActionTypes.CREATE_TASK:
    case ActionTypes.UPDATE_TASK: {
      return {
        ...state,
        isUpdateTaskPending: true,
      }
    }

    case ActionTypes.UPDATE_TASK_SUCCESS: {
      const { user, payload: updatedTask } = action

      const { Escalated, ...uniqueTasks } = state.byStatus
      // const totalByStatusObj = {...state.totalByStatus}

      const allTasks = flatMap(uniqueTasks)

      const index = allTasks.findIndex(({ taskId }) => taskId === updatedTask.taskId)

      // const taskBeforeUpdate = allTasks[index]
      allTasks[index] = updatedTask
      // const taskAfterUpdate = allTasks[index]

      // totalByStatusObj[taskBeforeUpdate.status] = totalByStatusObj[taskBeforeUpdate.status] - 1
      // if(taskAfterUpdate.status.toLowerCase() === 'completed') {
      //   totalByStatusObj[STATUS.CLOSED] =  totalByStatusObj[STATUS.CLOSED] + 1
      // }
      // if(taskAfterUpdate.status.toLowerCase() === 'disregarded') {
      //   totalByStatusObj[STATUS.CLOSED] =  totalByStatusObj[STATUS.CLOSED] + 1
      // }
      // if(taskAfterUpdate.status.toLowerCase() !== 'disregarded' && taskAfterUpdate.status.toLowerCase() !== 'completed') {
      //   totalByStatusObj[taskAfterUpdate.status] = totalByStatusObj[taskAfterUpdate.status] + 1
      // }

      const tasks = allTasks.map(task => ({
        ...task,
        isEscalated: isEscalated(task, getUsername(user)),
      }))

      const updatedTasksByStatus = groupBy(tasks, 'status')
      updatedTask.isEscalated = isEscalated(updatedTask, getUsername(user))
      const escalated =
        updatedTask.isEscalated && !Escalated.find(({ taskId }) => taskId === updatedTask.taskId)
          ? [...Escalated, ...[updatedTask]]
          : Escalated
      // totalByStatusObj[STATUS.ESCALATED] = totalByStatusObj[STATUS.ESCALATED] + (escalated.length - Escalated.length)
      updatedTasksByStatus[STATUS.ESCALATED] = escalated

      return {
        ...state,
        byStatus: updatedTasksByStatus,
        // totalByStatus: totalByStatusObj,
        isUpdateTaskPending: false,
      }
    }

    default:
      return state
  }
}

export default tasksByWellReducer
