import { flatMap, groupBy } from 'lodash'

import * as ActionTypes from '../../actions/actionTypes'
import { TasksState } from '../../actions/tasksTypes'
import { TaskType } from '../../types'
import { STATUS } from '../../constants'
import { getUsername } from '../../utils'

const initialState: TasksState = {
  byStatus: {},
  totalByStatus: {},
  allWellIdsByTaskStatus: {},
  isTasksLookUpPending: false,
  isUpdateTaskPending: false,
  isTasksLookUpPendingByStatus: {},
}

const isEscalated = (task: TaskType, user: string) => {
  const hasEscalation = task.escalated && task.escalated.startsWith('true')
  const performedEscalation = task.escalatedBy && task.escalatedBy.includes(user.toLowerCase())

  return hasEscalation && performedEscalation
}

const tasksReducer = (state: TasksState = initialState, action): TasksState => {
  switch (action.type) {
    case ActionTypes.GET_OPENSEARCH_TASKS: {
      const newStatuses = {}
      if (typeof action.payload.status === 'string') {
        newStatuses[action.payload.status] = true
      } else {
        action.payload.status.forEach(s => {
          newStatuses[s] = true
        })
      }
      return {
        ...state,
        isTasksLookUpPendingByStatus: {
          ...state.isTasksLookUpPendingByStatus,
          ...newStatuses,
        },
      }
    }
    // case ActionTypes.GET_TASK:
    case ActionTypes.GET_TASKS: {
      return {
        ...state,
        isTasksLookUpPending: true,
      }
    }

    case ActionTypes.GET_TASKS_SUCCESS: {
      const totalTasksByStatusObj = { ...action.payload.totalTasksByStatus }

      Object.keys(totalTasksByStatusObj).forEach(key => {
        totalTasksByStatusObj[key] = false
      })

      return {
        ...state,
        byStatus: { ...state.byStatus, ...action.payload.tasksByStatus },
        // byStatus: state.byStatus,
        // totalByStatus: state.totalByStatus,
        totalByStatus: { ...state.totalByStatus, ...action.payload.totalTasksByStatus },
        allWellIdsByTaskStatus: action.payload.allWellIdsByTaskStatus,
        isTasksLookUpPending: false,
        isTasksLookUpPendingByStatus: {
          ...state.isTasksLookUpPendingByStatus,
          ...totalTasksByStatusObj,
        },
      }
    }

    case ActionTypes.CLEAR_OPENSEARCH_TASKS: {
      return {
        ...state,
        byStatus: {},
        totalByStatus: {},
        isTasksLookUpPending: false,
        isTasksLookUpPendingByStatus: {},
      }
    }

    // case ActionTypes.GET_TASK_SUCCESS: {
    //   const { payload: updatedTask, user } = action

    //   const { Escalated, ...uniqueTasks } = state.byStatus
    //   const allTasks = flatMap(uniqueTasks)

    //   const index = allTasks.findIndex(({ taskId }) => taskId === updatedTask.taskId)
    //   if (index !== -1) {
    //     allTasks[index] = updatedTask
    //   } else {
    //     allTasks.push(updatedTask)
    //   }

    //   const tasks = allTasks.map(task => ({
    //     ...task,
    //     isEscalated: isEscalated(task, getUsername(user)),
    //   }))

    //   const updatedTasksByStatus = groupBy(tasks, 'status')
    //   updatedTask.isEscalated = isEscalated(updatedTask, getUsername(user))
    //   const escalated = (updatedTask.isEscalated && !Escalated.find(({ taskId }) => taskId === updatedTask.taskId))?[...Escalated,...[updatedTask]]:Escalated

    //   updatedTasksByStatus[STATUS.ESCALATED] = escalated

    //   return {
    //     ...state,
    //     byStatus: updatedTasksByStatus,
    //     isTasksLookUpPending: false,
    //   }
    // }

    // case ActionTypes.CREATE_TASK_SUCCESS: {
    //   const { payload: task, user } = action

    //   const { Escalated, ...uniqueTasks } = state.byStatus
    //   const allTasks = flatMap(uniqueTasks)
    //   allTasks.push(task)

    //   const tasks = allTasks.map(task => ({
    //     ...task,
    //     isEscalated: isEscalated(task, getUsername(user)),
    //   }))

    //   const updatedTasksByStatus = groupBy(tasks, 'status')

    //   task.isEscalated = isEscalated(task, getUsername(user))
    //   const escalated = (task.isEscalated && !Escalated.find(({ taskId }) => taskId === task.taskId))?[...Escalated,...[task]]:Escalated

    //   updatedTasksByStatus[STATUS.ESCALATED] = escalated

    //   return {
    //     ...state,
    //     byStatus: updatedTasksByStatus,
    //     isUpdateTaskPending: false,
    //   }
    // }

    // case ActionTypes.GET_TASKS_SILENT: {
    //   return {
    //     ...state,
    //   }
    // }

    // case ActionTypes.CREATE_TASK:
    // case ActionTypes.UPDATE_TASK: {
    //   return {
    //     ...state,
    //     isUpdateTaskPending: true,
    //   }
    // }

    // case ActionTypes.UPDATE_TASK_SUCCESS: {
    //   const { user, payload: updatedTask } = action

    //   const { Escalated, ...uniqueTasks } = state.byStatus

    //   const allTasks =  flatMap(uniqueTasks)

    //   const index = allTasks.findIndex(({ taskId }) => taskId === updatedTask.taskId)

    //   allTasks[index] = updatedTask

    //   const tasks = allTasks.map(task => ({
    //     ...task,
    //     isEscalated: isEscalated(task, getUsername(user)),
    //   }))

    //   const updatedTasksByStatus = groupBy(tasks, 'status')
    //   updatedTask.isEscalated = isEscalated(updatedTask, getUsername(user))
    //   const escalated = (updatedTask.isEscalated && !Escalated.find(({ taskId }) => taskId === updatedTask.taskId))?[...Escalated,...[updatedTask]]:Escalated
    //   updatedTasksByStatus[STATUS.ESCALATED] = escalated

    //   return {
    //     ...state,
    //     byStatus: updatedTasksByStatus,
    //     isUpdateTaskPending: false,
    //   }
    // }

    default:
      return state
  }
}

export default tasksReducer
