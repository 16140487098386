import React from 'react'
import { Options } from 'material-table'

export const DATE_FORMAT = 'DD/MM/YYYY'
export const DATE_TIME_FORMAT = 'DD/MM/YYYY HH:mm:ss'
export const DATE_TIME_ISO = 'YYYY-MM-DD HH:mm:ss'
export const DATE_ISO = 'YYYY-MM-DD'

export const DEFAULT_WOR = 'Default'

export const VIS_TYPES = {
  PROD_SNAPSHOT: {
    name: 'prodSnapshot',
    link: 'https://opsvision.igap.int.origin.com.au/PIVision/PB/#/PBDisplays/Well - PN?currentelement=\\\\BCCRPIAF02\\IGwellsGeneric\\All\\Well Generic\\WELL_ID&starttime=-2&endtime=2',
  },
}

export const ROD_PUMP = 'Rod Pump'
export const PCP_PUMP = 'PCP'

export const FIN_YEAR_PREFIX = 'fin'

export const TASK_TYPE_EDIT_ROLE = ['WPA_PRODUCTION_ENGINEER', 'WPA_WELL_PERFORMANCE_TECHNICIAN']
export const WOR_CONFIG_EDIT_ROLE = ['WPA_PRODUCTION_ENGINEER', 'WPA_WELL_PERFORMANCE_TECHNICIAN']
export const WELL_REVIEW_EDIT_ROLE = ['WPA_PRODUCTION_ENGINEER', 'WPA_WELL_PERFORMANCE_TECHNICIAN']

export const VIEWS = {
  UNKNOWN: 'unknown',
  DASHBOARD: 'dashboard',
  TASKS: 'tasks',
  FILTERS: 'filters',
  WATCHLISTS: 'watch-lists',
  WELLLISTS: 'well-lists',
}

export const STATUS = {
  TO_DO: 'To Do',
  IN_PROGRESS: 'In Progress',
  COMPLETED: 'Completed',
  DISREGARDED: 'Disregarded',
  EXPIRED: 'Expired',
  ESCALATED: 'Escalated',
  SUPERSEDED: 'Superseded',
  BENEFIT: 'BENEFIT',
  CLOSED: 'Closed',
}

export const TASK_TABS = {
  ASSIGNED: 0,
  TO_DO: 1,
  ESCALATED: 2,
  CLOSED: 3,
  LAPSED: 4,
  SUSPENSIONS: 5,
  BENEFITS: 6,
}

export const TABS = {
  TASKS: 'task-tabs',
  WELL_DETAILS: 'well-details-tab',
  DASHBOARD: 'dashboard-tab',
}

export const WELL_TABLE_ATTRIBUTES = [
  'wellId',
  'assetArea',
  'facility',
  'operatingArea',
  'wellOperatorRun',
  'wellHeadType',
  'wellOperationalStatus',
  'pumpSpeedActualRPM',
  'gasRateAvg1hGJ',
  'gasRateGasDayGJ',
  'gasSystemCapacityVarianceGJ',
  'gasOperationalVarianceGJ',
  'gasOperationalTargetGJ',
  'gasSystemCapacityGJ',
  'waterRateGasDayM3',
  'waterFlowMagFromSeparatorAvg1hM3',
  'tubingFlowMeterAvg1hM3',
  'downholeGaugeFluidLevel',
  'workoverType',
  'workoverDate',
  'actionOwner',
  'artificialLiftType',
  'pwrtRecoveryStatus',
  'pwrtRecoveryStatusDays',
  'lastReviewDate',
  'nextReviewDate',
  'wellSiteCode',
  'alsCode',
  'wellSiteCodes',
  'alsCodes',
  'tdWEC_Mode',
  'tdTag',
  'tdMethod',
  'tdStatus',
  'tdFailureRisk',
  'daysSincePostTurnDown',
  'subSurfaceLiftType',
  'separatorBypassInstalled',
  'sspInstalled',
  'WECMode',
  'piFCVPositionFeedback',
  'piSeparatorLevel',
  'piWECPCPEfficiencyAverage1h',
  'piSurfaceTransferPumpControllerMode',
  'piSeparatorGasPressure',
  'piWellheadSkidType',
  'piWaterGatheringPressure',
  'piGasGatheringPressure',
  'piPowerSupplyType',
  'piPumpTorqueAverage1h',
  'piCasingPressureRateofBleedDown',
  'piWECPCPTheoreticalPumpDisplacement',
  'wellMaintenanceStatus',
  'wellDevelopmentPackage',
  'designatedProductionEngineer',
  'wellSiteSLT',
  'alsSLT',
  'pendingIntervention',
  'openTasks',
  'assignedTasks',
  'optimalFluidLevel',
  'wecPumpMaxSpeedSetpoint',
  'casingPressureControlActingOnFcvSetpoint',
]

export const TABLE_DEFAULT_PROPS: { style: React.CSSProperties; options: Options<{}> } = {
  style: {
    color: '#707070',
    fontWeight: 600,
  },

  options: {
    addRowPosition: 'first',
    emptyRowsWhenPaging: false,
    padding: 'dense',
    pageSize: 25,
    pageSizeOptions: [25, 50, 100],
    thirdSortClick: false,
    maxBodyHeight: 'calc(100vh - 350px)',
    headerStyle: {
      flexDirection: 'row', // override flex row-reverse direction for numeric fields
      paddingRight: 16,
      color: '#fff',
      backgroundColor: '#F84516',
      fontWeight: 600,
      textAlign: 'center',
    },

    // NOTE: cellStyle - seems an undocumented prop
    // @ts-ignore
    cellStyle: {
      textAlign: 'center',
    },
  },
}

export const LINK_CELL_STYLE = {
  maxWidth: '300px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}

export const LEVER_DEFAULT_VALUES = {
  Production: 'Production',
  Reliability: 'Reliability',
  Neutral: 'Neutral',
  Decarbonisation: 'Decarbonisation',
}

export const ERROR_MESSAGES = {
  STABILISATION_PERIOD: 'Please enter a number 0-9999',
  PRIORITY: 'Please enter a number 0-1000',
  REQUIRED: 'Required field',
  UNIQUE: 'Must be unique',
}

export const PWRT_STATUSES = {
  EXCLUDED: 'Excluded',
  RECOVERED: 'Recovered',
  IN_RECOVERY: 'In Recovery',
  FAILED: 'Failed',
  AWAITING_RESTART: 'Awaiting Restart',
}

// Well Review form options

export const CURRENT_PLAN_DROP_DOWN_VALUES = [
  'WIR Required',
  'Awaiting Intervention',
  'Post Workover Startup',
  'Optimisation Plan Active',
  'Troubleshooting Well Performance Issue',
  'Trial Well - PE Consult Required',
  'To be P&A',
  'Other',
]

export const CURRENT_ARTIFICIAL_LIFT_VALUES = [
  { value: 'PCP' },
  { value: 'iPCP' },
  { value: 'Highset PCP' },
  { value: 'RRP' },
  { value: 'Tbg RRP' },
  { value: 'ESP' },
  { value: 'ESPCP' },
  { value: 'Jet Pump' },
  { value: 'Free Flow' },
  { value: 'Gas Lift' },
  { value: 'Plunger Lift' },
  { value: 'Velocity String' },
  { value: 'Other', other: true },
]

export const CURRENT_SURFACE_DRIVE_EQUIPMENT_VALUES = [
  { value: 'Drivehead' },
  { value: 'BPU' },
  { value: 'TU' },
  { value: 'LRP' },
  { value: 'HRP' },
  { value: 'FF Config' },
  { value: 'Other', other: true },
]

export const SURFACE_PROCESSING_EQUIPMENT_VALUES = [
  { value: 'WHS' },
  { value: 'ODI' },
  { value: 'KODI' },
  { value: 'SOLO' },
  { value: 'ONE' },
  { value: 'SSP' },
  { value: 'WH Compression' },
  { value: 'WHS w/Water Bypass' },
  { value: 'WHS w/Gas Bypass' },
  { value: 'WHS Combo' },
  { value: 'KODI Bypass' },
  { value: 'Other', other: true },
]

export const OPTIMISATION_STRATEGY_VALUES = [
  {
    value: 'PCP Standard DHG Control',
    tooltip:
      'Strategy to target a fluid level over sensor equal to the lowest level the DHG can read +1- 2m. Drawdown rate >/= 20m/d. Operating speed window </=100rpm. Casing backpressure is not in use.',
  },
  {
    value: 'PCP Standard DHG Control with Exception(s)',
    tooltip: 'Free text description of applied strategy required in notes',
  },
  {
    value: 'PCP Conservative DHG Control',
    tooltip:
      'Strategy to target a fluid level over sensor equal to the lowest level the DHG can read + 1-2m. Drawdown rate <10m/d. Operating speed window </=100rpm. Casing backpressure may be in use.',
  },
  {
    value: 'PCP Conservative DHG Control with Exception(s)',
    tooltip: 'Free text description of applied strategy required in notes',
  },
  {
    value: 'PCP Speed Control',
    tooltip:
      'The preferred WEC method is Speed Control. Regular speed setpoint changes should be made to validate and maintain fluid level at pump intake.',
  },
  {
    value: 'PCP IEC',
    tooltip:
      'The preferred WEC method is Incremental Efficiency Control (IEC). Regular max speed setpoint changes should be made to validate and maintain fluid level at pump intake.',
  },
  {
    value: 'RRP Optimiser Mode',
    tooltip:
      'The preferred RRP control mode is RRP Optimiser. Changes made within this control mode are made within XSPOC.',
  },
  {
    value: 'RRP Single/Dual Speed',
    tooltip:
      'The preferred RRP control mode is RRP single/dual speed. Changes made within this control mode are made within FastTools.',
  },
  {
    value: 'Non DHG control with Exception(s)',
    tooltip: 'Free text on deviation from this mode to be stated',
  },
  {
    value: 'PCP Cycling',
    tooltip:
      'The PCP is currently operating under an approved cycling plan. This strategy should be selected when cycling is used in conjunction with any other PCP control mode.',
  },
  {
    value: 'Free flow',
    tooltip:
      'The ALS is currently not in operation or not installed.  The well is either oeprating under critical lift conditions and a defined gas flow path (annulus or tubing) or is OTL awaiting future intervention.',
  },
  { value: 'Other', tooltip: 'Free text description of applied strategy required in notes' },
]

export const PERFORMANCE_CONSTRAINTS_VALUES = [
  'None',
  'Gas gathering network',
  'Water gathering network',
  'Produced fluid (frac sand, interburden, etc)',
  'Sub-optimal completion',
  'Wellsite (ODI, etc)',
  'Reservoir inflow',
  'Gas Ingress',
  'Impairment',
  'Integrity',
  'Other',
]

export const FUTURE_DOWNHOLE_VALUES = [
  'ALS Like for Like replacement',
  'ALS to RRP conversion',
  'ALS to PCP conversion',
  'ALS to FF conversion',
  'Other ALS conversion',
  'Highset pump install',
  'Sump Highset pump',
  'FS treatment',
  'Scale treatment',
  'Acid treatment',
  'Refrac',
  'Impairment remediation',
  'Trial well',
  'Liner pull',
  'Casing/liner perforation',
  'Tubing perforation',
  'CCP upgrade',
  'Shoe track millout',
  'Fishing',
  'Rod-Respace',
  'TC/Suspend/P&A',
]

export const FUTURE_SURFACE_VALUES = [
  'FCV upgrade (high DP from wellhead to separator)',
  'Gas loop line (high gas line pressure)',
  'Surface pump (high water line pressure)',
  'Orifice plate change (high/low dP across current orifice plate)',
  'ODI/ONE to KODI upgrade',
  'ODI/ONE to Separator upgrade',
  'Separator to ODI/ONE upgrade',
  'Compression',
  'Other skid upgrades',
  'KODI to Separator upgrade',
  'Wellsite Pressure Rating Upgrade',
  'KODI bypass',
  'KODI water line upgrade',
  'Separator water bypass',
  'Separator gas bypass',
  'N/A',
]
